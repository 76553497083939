import React, { Component } from 'react';

export const NAV = {
    h2s: [{
        h3s: [{
            title: 'C.V.',
            url: 'cv'
        },
        // {
        //     title: 'Portfolio',
        //     url: 'portfolio'
        // },
        // {
        //     h4s: [
        //     // {
        //     //     title: 'Maps',
        //     //     url: 'maps'
        //     // },
        //     // {
        //     //     title: 'Organic Laws',
        //     //     url: 'organic-laws'
        //     // },
        //     // {
        //     //     title: 'Quanta',
        //     //     url: 'quanta'
        //     // }
        //     ],
        //     title: 'Projects',
        //     url: 'projects'
        // },
        // {
        //     title: 'Wedding',
        //     url: 'wedding'
        // }
        ],
        images: [{
            alt: '',
            id: 'homepageLogo',
            src: '_images/logo.png'
        }],
        title: 'Home',
        url: '.'
    }],
    id: 'siteNav'
};

export class Breadcrumbs extends Component {
    render() {
        var breadcrumb = null,
            breadcrumbs = [],
            classNames = [];

        if (this.props.isCurrent) {
            classNames.push('current');

            if (this.props.rel === 'index') {
                classNames.push('hidden');
            }
        }

        for (let index = 0; index < this.props.breadcrumbs.length; index++) {
            breadcrumb = this.props.breadcrumbs[index];
            breadcrumbs.push(<li key={index + 1} className={classNames.join(' ')} itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                <meta itemProp="position" content={index + 1}/>
                <a href={breadcrumb.href} rel={breadcrumb.rel} title={breadcrumb.title} itemProp="item">
                    <span itemProp="name">{breadcrumb.title}</span>
                </a>
            </li>);
        }

        if (!!breadcrumbs) {
            return <ol id="breadcrumbs" itemScope itemType="https://schema.org/BreadcrumbList">
                {breadcrumbs}
            </ol>
        };
    }
}
export class Copyright extends Component {
    render() {
        return <p id="copyright">
            © <a title="Email cah@cahutton.com" href="mailto:cah@cahutton.com">C. A. Hutton</a>
        </p>;
    }
}
export class ErrorBoundary extends Component {
    componentDidCatch(error, info) {
        this.setState({
            error: error,
            info: info
        });
    }
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            info: null
        };
    }
    render() {
        if (this.state.error) {
            return <div>
                <pre>{this.state.error.toString()}</pre>
                <hr/>
                <pre>{JSON.stringify(this.state.info)}</pre>
            </div>;
        }

        return this.props.children;
    }
}
export class Footer extends Component {
    render() {
        return <footer>
            {!!this.props.breadcrumbs &&
                <Breadcrumbs breadcrumbs={this.props.breadcrumbs}/>
            }
            <Copyright/>
        </footer>;
    }
}
export class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showNav: props.showNavOnMount === true
        };
        this.hideNav = this.hideNav.bind(this);
        this.showNav = this.showNav.bind(this);
    }
    componentDidMount() {
        if (this.state.showNav) {
            setTimeout(this.hideNav, 1000);
        }
    }
    hideNav() {
        this.setState({
            showNav: false
        });
    }
    render() {
        return <header onMouseEnter={this.showNav} onMouseLeave={this.hideNav} onClick={this.state.showNav ? this.hideNav : this.showNav}>
            {!!this.props.nav ?
                <Nav nav={this.props.nav} innerNav={<Nav nav={NAV}/>} current={this.props.current} show={this.state.showNav}/>
            :
                <Nav nav={NAV} current={this.props.current} show={this.state.showNav}/>
            }
        </header>;
    }
    showNav() {
        this.setState({
            showNav: true
        });
    }
}
export class Nav extends Component {
    getNav(subnavArray, currentHref) {
        return subnavArray.h2s.reduce((h2Accumulator, h2CurrentValue, h2CurrentIndex, h2Array) => {
            h2Accumulator.push(<div key={h2CurrentIndex}>
                <a href={h2CurrentValue.url} title={h2CurrentValue.title}>
                    {h2CurrentValue.images &&
                        h2CurrentValue.images.reduce((h2ImgAccumulator, h2ImgCurrentValue, h2ImgCurrentIndex, h2ImgArray) => {
                            h2ImgAccumulator.push(<img key={h2ImgCurrentValue.id} id={h2ImgCurrentValue.id} src={h2ImgCurrentValue.src} alt={h2ImgCurrentValue.alt}/>);

                            return h2ImgAccumulator;
                        }, [])
                    }
                    <h2 className="visuallyhidden">{h2CurrentValue.title}</h2>
                </a>
                {h2CurrentValue.h3s &&
                    <ol>
                        {h2CurrentValue.h3s.reduce((h3Accumulator, h3CurrentValue, h3CurrentIndex, h3Array) => {
                            h3Accumulator.push(<li key={h3CurrentIndex} className={h3CurrentValue.url === currentHref ? 'current' : null}>
                                {h3CurrentValue.url === currentHref ?
                                    <h3>{h3CurrentValue.title}</h3>
                                :
                                    <a href={h3CurrentValue.url} target={h3CurrentValue.target}>
                                        <h3>{h3CurrentValue.title}</h3>
                                    </a>
                                }
                                {h3CurrentValue.h4s &&
                                    <ol>
                                        {h3CurrentValue.h4s.reduce((h4Accumulator, h4CurrentValue, h4CurrentIndex, h4Array) => {
                                            h4Accumulator.push(<li key={h4CurrentIndex} className={h4CurrentValue.url === currentHref ? 'current' : null}>
                                                <a href={h4CurrentValue.url}>
                                                    <h4>{h4CurrentValue.title}</h4>
                                                </a>
                                            </li>);

                                            return h4Accumulator;
                                        }, [])
                                        }
                                    </ol>
                                }
                            </li>);

                            return h3Accumulator;
                        }, [])
                        }
                    </ol>
                }
            </div>);

            return h2Accumulator;
        }, []);
    }
    render() {
        return <nav id={this.props.nav.id} className={!!this.props.show ? 'show' : null}>
            {this.getNav(this.props.nav, this.props.current)}
            {this.props.innerNav}
        </nav>;
    }
}
export class PageTitle extends Component {
    render() {
        return <h1 id="pageTitle">{this.props.title}</h1>;
    }
}
