import React, { Component } from 'react';

import { ErrorBoundary, Footer, Header } from './_scripts/common.jsx';
import { hydrateIfWindow as hydrate } from './_scripts/hydrate.jsx';


export const BREADCRUMBS = [{
    href: '/',
    rel: 'index',
    title: 'C. A. Hutton'
}];

export const ROOT = <ErrorBoundary>
    <div id="root">
        <Header current={BREADCRUMBS[0].href} showNavOnMount={true}/>
        <main id="homepage" itemScope itemType="http://schema.org/Person">
            <div>
                <h1 className="visuallyhidden">{BREADCRUMBS[BREADCRUMBS.length - 1].title}</h1>
                <meta itemProp="givenName" content="Corey"/>
                <meta itemProp="additionalName" content="A."/>
                <meta itemProp="familyName" content="Hutton"/>
                <meta itemProp="url" content="https://cahutton.com"/>
                <meta itemProp="email" content="cah@cahutton.com"/>
                <img src="https://cahutton.com/_images/logo.png" alt=""/>
            </div>
            <div>
                <a itemProp="sameAs" href="https://github.com/cahutton" target="_blank" rel="noopener" title="On GitHub">
                    <img src="_images/social/GitHub.png" alt=""/>
                </a>
                <a itemProp="sameAs" href="https://www.linkedin.com/in/cahutton" target="_blank" rel="noreferrer" title="On LinkedIn">
                    <img src="_images/social/LinkedIn.png" alt=""/>
                </a>
            </div>
        </main>
        <Footer breadcrumbs={BREADCRUMBS}/>
    </div>
</ErrorBoundary>;

hydrate(ROOT);
